import { useNewsletter as coreUseNewsletter } from '@shopware-pwa/composables-next';
import { newsletterSubscribe } from '@shopware-pwa/api-client';
import { useI18n } from 'vue-i18n';

export function useNewsletter() {
  const { apiInstance } = useShopwareContext();
  const coreFunctionality = coreUseNewsletter();
  const { t } = useI18n();
  const config = useRuntimeConfig();

  async function newsletterSubscribe$1(params: any) {
    return await newsletterSubscribe({ ...params }, apiInstance);
  }

  const newsletterUnsubscribe = async (email: string, source: string) => {
    await apiInstance.invoke.post(
      'store-api/crossengage/newsletter/unsubscribe',
      {
        email,
        source,
      }
    );
  };

  const htmlContent = ref('');
  const urlToShopPage = t(
    'components.' +
      config.BRAND +
      '.newsletterSubscription.newsletterForm.notice'
  );

  async function fetchContentFromNewsletterPage() {
    try {
      const response = await fetch(urlToShopPage);
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      htmlContent.value =
        doc.getElementsByClassName('newsletter-info-page__section')[0]
          ?.innerHTML ?? '';
    } catch (error) {
      console.error(error);
    }
  }

  return {
    ...coreFunctionality,
    newsletterSubscribe: newsletterSubscribe$1,
    newsletterUnsubscribe,
    fetchContentFromNewsletterPage,
    htmlContent,
  };
}
